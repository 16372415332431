button, .p-checkbox-box {
  box-shadow: none !important;
}

a {
  color: var(--text-color-secondary) !important;
  text-decoration: none !important;
  box-shadow: none !important;
  :hover {
    color: var(--text-color) !important;
  }
}

.p-tabview-panels {
  padding: 1.25rem 0 0 !important;;
}

.table-action-buttons {
  @include styleclass('flex flex-row justify-content-end align-items-center gap-2')
}

.table-row-name {
  &:hover {
    cursor: pointer !important;
  }
}

.table-action {
  // TODO style table actions
}

.table-top-button {
  @include styleclass('text-right pr-0');
}

// TODO check a style
.p-tabview-panels, a {
  background: transparent !important;
}

.p-datatable-header {
  padding: 0 0 1rem 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 1rem;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 0.8rem 0.5rem;
}

.p-treetable-scrollable-header-box {
  padding-right: 0 !important;
}

.raily-row-disabled {
  background: lighten($shade800, 3%) !important;
}

.raily-row-modified {
  background: darken($shade800, 3%) !important;
}

.table-card {
  @include styleclass('container surface-card p-3 shadow-2 border-round flex-auto min-h-full')
}

.action-buttons {
  @include styleclass('flex flex-row justify-content-end gap-2')
}

.raily-form {
  @include styleclass('flex flex-column gap-3 flex-auto p-fluid')
}

.raily-form-row {
  @include styleclass('flex flex-row gap-2')
}

.raily-mobile-screen {
  @include styleclass('w-screen h-screen overflow-y-auto')
}

.raily-action-bar-mobile {
  @include styleclass('p-3 h-5rem surface-card shadow-4')
}

.raily-mobile-page-padding {
  height: 50px;
}

.icon-button {
  cursor: pointer;
  &:hover {
    color: var(--gray-50) !important;
  }
  &:active {
    color: var(--gray-400) !important;
  }
}

.p-selectbutton .p-button {
  background: var(--surface-ground);
}
