$primaryColor: #15b0a4 !default;
$primaryLightColor: opacify($primaryColor, 0.5) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: 10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -25%) !default;
$primaryTextColor: #1c2127 !default;

$highlightBg:rgba(94, 234, 212, .16) !default;
$highlightTextColor: rgba(255,255,255,.87) !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import './_variables';
@import './theme-base/_components';
@import './_extensions';
@import './_exports';
